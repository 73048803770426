import React from 'react';
import NextHead from 'next/head';
import { useRouter } from 'next/router';
// Helpers
import { getEnvLink } from 'helpers/routes';
import { isProduction } from 'helpers/env';

const DEFAULT_IMAGE = '/homepage.jpg';

type Props = {
  title: string;
  description: string;
  image?: string;
  structuredData?: Record<string, unknown> | null;
  canonicalLink?: string | null;
};

const Head = (props: Props): JSX.Element => {
  const { asPath } = useRouter();

  const { title, description, image } = props;
  const imageUrl = image || `${getEnvLink()}${DEFAULT_IMAGE}`;
  const url = `${getEnvLink()}${asPath}`;

  return (
    <NextHead>
      {/* <!-- Primary Meta Tags --> */}
      <title>{title}</title>
      {props.structuredData && (
        <script
          type="application/ld+json"
          dangerouslySetInnerHTML={{
            __html: JSON.stringify(props.structuredData),
          }}
        ></script>
      )}
      {props.canonicalLink && (
        <link rel="canonical" href={props.canonicalLink} />
      )}

      <meta
        httpEquiv="delegate-ch"
        content="sec-ch-width https://cdn-media.millions.co; sec-ch-dpr https://cdn-media.millions.co; sec-ch-viewport-width https://cdn-media.millions.co;"
      />
      <link rel="icon" href="/favicon.png" />
      <meta name="title" content={title} />
      <meta name="description" content={description} />
      <meta
        name="viewport"
        content="width=device-width, initial-scale=1.0, maximum-scale=1.0, user-scalable=no"
      />
      {!isProduction && <meta name="robots" content="noindex" />}

      {/* <!-- Open Graph / Facebook --> */}
      <meta
        property="fb:app_id"
        content={process.env.NEXT_PUBLIC_FACEBOOK_APP_ID}
      />
      <meta property="og:type" content="website" />
      <meta property="og:url" content={url} />
      <meta property="og:title" content={title} />
      <meta property="og:description" content={description} />
      <meta property="og:image" content={imageUrl} />

      {/* <!-- Twitter --> */}
      <meta property="twitter:card" content="summary_large_image" />
      <meta property="twitter:url" content={url} />
      <meta property="twitter:title" content={title} />
      <meta property="twitter:description" content={description} />
      <meta property="twitter:image" content={imageUrl} />
    </NextHead>
  );
};

export default Head;
