import cn from 'classnames';
// Styles
import styles from './Loader.module.scss';

type LoaderColor = 'harvest-gold' | 'white' | 'black';

type LoaderSize =
  | 'xxSmall'
  | 'xSmall'
  | 'small'
  | 'medium'
  | 'large'
  | 'xLarge'
  | 'xxLarge';

type LoaderProps = {
  loading: boolean;
  size?: LoaderSize;
  color?: LoaderColor;
  related?: boolean;
  className?: string;
};

const Loader = ({
  loading,
  size = 'small',
  color = 'harvest-gold',
  related,
  className,
}: LoaderProps) =>
  loading ? (
    <span
      className={cn(
        styles.loader,
        styles[`color-${color}`],
        styles[`size-${size}`],
        { [styles.related]: !!related },
        className
      )}
      aria-label="loader"
    />
  ) : null;

export default Loader;
