import cn from 'classnames';
// Ui
import Icon, { IconNames } from 'ui/Icon/Icon';
// Ui2
import Loader from 'ui2/Loader/Loader';
// Styles
import styles from './Button.module.scss';

export type ButtonColor =
  | 'black'
  | 'god-grey'
  | 'black-gold'
  | 'harvest-gold'
  | 'chateau-green'
  | 'maximum-red'
  | 'transparent-gold'
  | 'white'
  | 'transparent';
export type ButtonSize = 'xSmall' | 'small' | 'default';
export type ButtonIcon = IconNames;

export type BasicButtonProps = {
  loading?: boolean;
  xs?: ButtonSize;
  s?: ButtonSize;
  m?: ButtonSize;
  ml?: ButtonSize;
  l?: ButtonSize;
  xl?: ButtonSize;
  xxl?: ButtonSize;
  color?: ButtonColor;
  startIcon?: ButtonIcon;
  endIcon?: ButtonIcon;
};

export type ButtonProps = React.ComponentPropsWithoutRef<'button'> &
  BasicButtonProps;

const Button = ({
  children,
  xs = 'default',
  s,
  m,
  ml,
  l,
  xl,
  xxl,
  color = 'black',
  loading,
  className,
  disabled,
  startIcon,
  endIcon,
  ...rest
}: ButtonProps) => {
  return (
    <button
      className={cn(
        styles.button,
        styles[`color-${color}`],
        { [styles[`size-xs-${xs}`]]: xs },
        { [styles[`size-s-${s}`]]: s },
        { [styles[`size-m-${m}`]]: m },
        { [styles[`size-ml-${ml}`]]: ml },
        { [styles[`size-l-${l}`]]: l },
        { [styles[`size-xl-${xl}`]]: xl },
        { [styles[`size-xxl-${xxl}`]]: xxl },
        { [styles.withEndIcon]: !!endIcon },
        { [styles.withStartIcon]: !!startIcon },
        { [styles.loading]: loading },
        className
      )}
      disabled={disabled || loading}
      {...rest}
    >
      {loading && (
        <Loader
          loading={loading}
          size="small"
          color={
            color === 'black' || color === 'black-gold'
              ? 'harvest-gold'
              : color === 'harvest-gold' || color === 'white'
              ? 'black'
              : 'white'
          }
        />
      )}

      <span className={styles.buttonContent}>
        {startIcon && <Icon name={startIcon} className={styles.buttonIcon} />}
        {children}
        {endIcon && <Icon name={endIcon} className={styles.buttonIcon} />}
      </span>
    </button>
  );
};

export default Button;
