import React from 'react';
import cn from 'classnames';
// Icons
import ArrowLongLeft from '../../public/images/icons/arrow-long-left.svg';
import ArrowLongRight from '../../public/images/icons/arrow-long-right.svg';
import ArrowDownBold from '../../public/images/icons/arrow-down-bold.svg';
import ArrowDownThin from '../../public/images/icons/arrow-down-thin.svg';
import ArrowLongUp from '../../public/images/icons/arrow-long-up.svg';
import ArrowRightThin from '../../public/images/icons/arrow-right-thin.svg';
import Avion from '../../public/images/icons/avion.svg';
import Calendar from '../../public/images/icons/calendar.svg';
import CameraVideo from '../../public/images/icons/camera-video.svg';
import Camera from '../../public/images/icons/camera.svg';
import Chart from '../../public/images/icons/chart.svg';
import ChatSubmit from '../../public/images/icons/chat-submit.svg';
import Check from '../../public/images/icons/check.svg';
import ChevronDownClean from '../../public/images/icons/chevron-down-clean.svg';
import ChevronUpClean from '../../public/images/icons/chevron-up-clean.svg';
import ChevronDown from '../../public/images/icons/chevron-down.svg';
import ChevronUp from '../../public/images/icons/chevron-up.svg';
import ChevronRight from '../../public/images/icons/chevron-right.svg';
import ChevronRightNew from '../../public/images/icons/chevron-right-new.svg';
import ChevronLeft from '../../public/images/icons/chevron-left.svg';
import ChevronLeftNew from '../../public/images/icons/chevron-left-new.svg';
import Close from '../../public/images/icons/close.svg';
import CreditCard from '../../public/images/icons/credit-card.svg';
import Download from '../../public/images/icons/download.svg';
import Edit from '../../public/images/icons/edit.svg';
import EmptyChat from '../../public/images/icons/empty-chat.svg';
import EnterKey from '../../public/images/icons/enter-key.svg';
import Filter from '../../public/images/icons/filter-icon.svg';
import Info from '../../public/images/icons/info.svg';
import Link from '../../public/images/icons/link.svg';
import Linkedin from '../../public/images/icons/linkedin.svg';
import LoggedMenuDropdown from '../../public/images/icons/logged-menu-dropdown.svg';
import MenuShoppingCart from '../../public/images/icons/menu-shopping-cart.svg';
import Microphone from '../../public/images/icons/microphone.svg';
import MinusInCircle from '../../public/images/icons/minus-in-circle.svg';
import Minus from '../../public/images/icons/minus.svg';
import MobileShare from '../../public/images/icons/mobile-share.svg';
import PaperPlane from '../../public/images/icons/paper-plane.svg';
import Organisation from '../../public/images/icons/organisation.svg';
import PhotoCam from '../../public/images/icons/photo-cam.svg';
import Play from '../../public/images/icons/play.svg';
import PlusInCircle from '../../public/images/icons/plus-in-circle.svg';
import Plus from '../../public/images/icons/plus.svg';
import Record from '../../public/images/icons/record.svg';
import Search from '../../public/images/icons/search.svg';
import ShoppingBag from '../../public/images/icons/shopping-bag.svg';
import ShoppingCartBordered from '../../public/images/icons/shopping-cart-bordered.svg';
import ShoppingCart from '../../public/images/icons/shopping-cart.svg';
import SortIcon from '../../public/images/icons/sort-icon.svg';
import Trash from '../../public/images/icons/trash.svg';
import Undo from '../../public/images/icons/undo.svg';
import User from '../../public/images/icons/user.svg';
import Warning from '../../public/images/icons/warning.svg';
import Webcam from '../../public/images/icons/webcam.svg';
import Tag from '../../public/images/icons/tag.svg';
import Upload from '../../public/images/icons/upload.svg';
import UploadFile from '../../public/images/icons/upload-file.svg';
import UploadFolder from '../../public/images/icons/upload-folder.svg';
import UploadBordered from '../../public/images/icons/upload-bordered.svg';
import Film from '../../public/images/icons/film.svg';
import CloseInCircle from '../../public/images/icons/close-o.svg';
import VerificationVideoIcon from '../../public/images/icons/verification-video.svg';
import RecordAma from '../../public/images/icons/record-ama.svg';
import Copy from '../../public/images/icons/copy.svg';
import CopyV2 from '../../public/images/icons/v2/copy.svg';
import CopyLink from '../../public/images/icons/copy_link.svg';
import TShirt from '../../public/images/icons/t-shirt.svg';
import MessageQuestion from '../../public/images/icons/message-question.svg';
import Cash from '../../public/images/icons/cash.svg';
import Facebook from '../../public/images/icons/facebook.svg';
import FacebookCircle from '../../public/images/icons/facebook-circle.svg';
import Instagram from '../../public/images/icons/instagram.svg';
import InstagramFilled from '../../public/images/icons/instagram-filled.svg';
import Twitter from '../../public/images/icons/twitter.svg';
import Youtube from '../../public/images/icons/youtube.svg';
import Tiktok from '../../public/images/icons/tiktok.svg';
import Profile from '../../public/images/icons/profile.svg';
import Share from '../../public/images/icons/share.svg';
import Playback from '../../public/images/icons/playback.svg';
import Pause from '../../public/images/icons/pause.svg';
import VolumeHigh from '../../public/images/icons/volume-high.svg';
import VolumeOff from '../../public/images/icons/volume-off.svg';
import CircleMinus from '../../public/images/icons/circle-minus.svg';
import CirclePlus from '../../public/images/icons/circle-plus.svg';
import RoundSorting from '../../public/images/icons/round-sorting.svg';
import Stars from '../../public/images/icons/stars.svg';
import Checkmark from '../../public/images/icons/checkmark.svg';
import CircleCheckmark from '../../public/images/icons/circle-checkmark.svg';
import Circle from '../../public/images/icons/circle.svg';
import CircleFilled from '../../public/images/icons/circle-filled.svg';
import Square from '../../public/images/icons/square.svg';
import CloseNew from '../../public/images/icons/close-new.svg';
import Dialogue from '../../public/images/icons/dialogue.svg';
import Error from '../../public/images/icons/error.svg';
import Infoalternate from '../../public/images/icons/infoalternate.svg';
import Attention from '../../public/images/icons/attention.svg';
import CircleAddAlternate from '../../public/images/icons/circle-add-alternate.svg';
import MoreAlternate from '../../public/images/icons/more-alternate.svg';
import PendingActions from '../../public/images/icons/pending-actions.svg';
import Star from '../../public/images/icons/star.svg';
import Eye from '../../public/images/icons/eye.svg';
import EyeBlocked from '../../public/images/icons/eye-blocked.svg';
import GoogleSignInOfficial from '../../public/images/sign-up/google-sign-in-official.svg';
import Alert from '../../public/images/icons/alert.svg';
import MailReply from '../../public/images/icons/mail-reply.svg';
import WorldConnection from '../../public/images/icons/world-connection.svg';
import EditIcon from '../../public/images/icons/edit-pencil.svg';
import TrashIcon from '../../public/images/icons/trash.svg';
import UndoIcon from '../../public/images/icons/undo.svg';
import UploadLogo from '../../public/images/icons/upload-logo.svg';
import RequestMerch from '../../public/images/icons/request-merch.svg';
import MailSignUp from '../../public/images/sign-up/mail_sign_up.svg';
import GoogleSignInRedesigned from '../../public/images/sign-up/google-sign-in-redesigned.svg';
import FacebookSignInRedesigned from '../../public/images/sign-up/facebook-sign-in-redesigned.svg';
import Suitcase from '../../public/images/icons/suitcase.svg';
import Meatballs from '../../public/images/icons/meatballs-menu.svg';
import Trophy from '../../public/images/icons/trophy.svg';
import RtmpIcon from '../../public/images/icons/rtmp-icon.svg';
import SignUpAthleteIcon from '../../public/images/icons/sign_up_athlete_icon.svg';
import SignUpFanIcon from '../../public/images/icons/sign_up_fan_icon.svg';
import SignUpContentCreatorIcon from '../../public/images/icons/sign_up_content_creator_icon.svg';
import SignUpOrganizationIcon from '../../public/images/icons/sign_up_organization_icon.svg';
import Attach from '../../public/images/icons/attach.svg';
import AddAnother from '../../public/images/icons/add-another.svg';
import Pdf from '../../public/images/icons/pdf.svg';
import Image from '../../public/images/icons//image.svg';
import Document from '../../public/images/icons//document.svg';
import IconInterview from '../../public/images/icons/icon-interview.svg';
// deprecated (to use for migration from font icons only)
import DeprecatedCircleCheck from '../../public/images/icons/deprecated-circle-check.svg';
import DeprecatedEye from '../../public/images/icons/deprecated-eye.svg';
import DeprecatedEyeBlocked from '../../public/images/icons/deprecated-eye-blocked.svg';
import BrowseDropImage from '../../public/images/icons/browse-drop-image.svg';
// v2
import CardTravel from '../../public/images/icons/v2/card-travel.svg';
import ColorLens from '../../public/images/icons/v2/color-lens.svg';
import CreditCardV2 from '../../public/images/icons/v2/credit-card.svg';
import Dashboard from '../../public/images/icons/v2/dashboard.svg';
import LocalAtm from '../../public/images/icons/v2/local-atm.svg';
import Loyalty from '../../public/images/icons/v2/loyalty.svg';
import Person from '../../public/images/icons/v2/person.svg';
import ImagePreview from '../../public/images/icons/v2/image-preview.svg';
import LinkedCamera from '../../public/images/icons/v2/linked-camera.svg';
import UploadV2 from '../../public/images/icons/v2/upload.svg';
import LinkV2 from '../../public/images/icons/v2/link.svg';
import LinkOff from '../../public/images/icons/v2/link-off.svg';
import OpenNew from '../../public/images/icons/v2/open-new.svg';
import TrophyWhite from '../../public/images/icons/v2/trophy.svg';
import SettingsV2 from '../../public/images/icons/settings-v2.svg';
import CameraV2 from '../../public/images/icons/camera-v2.svg';
import ArtV2 from '../../public/images/icons/v2/art.svg';
import Brand from '../../public/images/icons/v2/brand.svg';
import Dollar from '../../public/images/icons/v2/dollar.svg';
import Sales from '../../public/images/icons/v2/sales.svg';
import ShareV2 from '../../public/images/icons/v2/share.svg';
import CloseChat from '../../public/images/icons/close-chat.svg';
import OpenChat from '../../public/images/icons/open-chat.svg';
import CalendarV2 from '../../public/images/icons/v2/calendar.svg';
import CheckV2 from '../../public/images/icons/v2/check.svg';
import BinV2 from '../../public/images/icons/v2/bin.svg';
import LetterV2 from '../../public/images/icons/v2/letter.svg';
import PlusInCircleV2 from '../../public/images/icons/v2/small-plus-in-circle.svg';
import BuyCart from '../../public/images/icons/v2/buycart.svg';
import TipHand from '../../public/images/icons/v2/tip.svg';
import Settings from '../../public/images/icons/v2/settings.svg';
// Styles
import styles from './Icon.module.scss';

const ICON_NAMES = {
  'arrow-down-bold': ArrowDownBold,
  'arrow-down-thin': ArrowDownThin,
  'arrow-long-up': ArrowLongUp,
  'arrow-long-right': ArrowLongRight,
  'arrow-long-left': ArrowLongLeft,
  'arrow-right-thin': ArrowRightThin,
  avion: Avion,
  calendar: Calendar,
  'v2-calendar': CalendarV2,
  'camera-video': CameraVideo,
  camera: Camera,
  chart: Chart,
  'chat-submit': ChatSubmit,
  check: Check,
  'chevron-down-clean': ChevronDownClean,
  'chevron-up-clean': ChevronUpClean,
  'chevron-down': ChevronDown,
  'chevron-up': ChevronUp,
  'chevron-right': ChevronRight,
  'chevron-right-new': ChevronRightNew,
  'chevron-left': ChevronLeft,
  'chevron-left-new': ChevronLeftNew,
  close: Close,
  'credit-card': CreditCard,
  download: Download,
  edit: Edit,
  'empty-chat': EmptyChat,
  'enter-key': EnterKey,
  facebook: Facebook,
  'facebook-circle': FacebookCircle,
  filter: Filter,
  instagram: Instagram,
  'instagram-filled': InstagramFilled,
  info: Info,
  link: Link,
  linkedin: Linkedin,
  'logged-menu-dropdown': LoggedMenuDropdown,
  'menu-shopping-cart': MenuShoppingCart,
  microphone: Microphone,
  minus: Minus,
  'mobile-share': MobileShare,
  'paper-plane': PaperPlane,
  organisation: Organisation,
  'photo-cam': PhotoCam,
  play: Play,
  'minus-in-circle': MinusInCircle,
  'plus-in-circle': PlusInCircle,
  plus: Plus,
  'record-icon': Record,
  search: Search,
  'shopping-bag': ShoppingBag,
  'shopping-cart-bordered': ShoppingCartBordered,
  'shopping-cart': ShoppingCart,
  'sort-icon': SortIcon,
  stars: Stars,
  'round-sorting': RoundSorting,
  tiktok: Tiktok,
  trash: Trash,
  twitter: Twitter,
  undo: Undo,
  upload: Upload,
  'upload-file': UploadFile,
  'upload-folder': UploadFolder,
  'upload-bordered': UploadBordered,
  user: User,
  warning: Warning,
  webcam: Webcam,
  tag: Tag,
  film: Film,
  'close-in-circle': CloseInCircle,
  'verification-video': VerificationVideoIcon,
  'record-ama': RecordAma,
  copy: Copy,
  'copy-link': CopyLink,

  't-shirt': TShirt,
  'message-question': MessageQuestion,
  cash: Cash,
  youtube: Youtube,
  profile: Profile,
  share: Share,
  playback: Playback,
  pause: Pause,
  'volume-high': VolumeHigh,
  'volume-off': VolumeOff,
  'circle-minus': CircleMinus,
  'circle-plus': CirclePlus,
  checkmark: Checkmark,
  'circle-checkmark': CircleCheckmark,
  circle: Circle,
  'circle-filled': CircleFilled,
  square: Square,
  'close-new': CloseNew,
  dialogue: Dialogue,
  error: Error,
  infoalternate: Infoalternate,
  attention: Attention,
  'circle-add-alternate': CircleAddAlternate,
  'more-alternate': MoreAlternate,
  'pending-actions': PendingActions,
  star: Star,
  eye: Eye,
  'eye-blocked': EyeBlocked,
  'mail-reply': MailReply,
  'world-connection': WorldConnection,
  'edit-icon': EditIcon,
  'trash-icon': TrashIcon,
  'undo-icon': UndoIcon,
  'upload-logo': UploadLogo,
  'request-merch': RequestMerch,
  // deprecated (to use for migration from font icons only)
  'deprecated-circle-check': DeprecatedCircleCheck,
  'deprecated-eye': DeprecatedEye,
  'deprecated-eye-blocked': DeprecatedEyeBlocked,
  'browse-drop-image': BrowseDropImage,
  // v2
  'v2-card-travel': CardTravel,
  'v2-color-lens': ColorLens,
  'v2-credit-card': CreditCardV2,
  'v2-dashboard': Dashboard,
  'v2-local-atm': LocalAtm,
  'v2-loyalty': Loyalty,
  'v2-person': Person,
  'v2-image-preview': ImagePreview,
  'v2-linked-camera': LinkedCamera,
  'v2-upload': UploadV2,
  'v2-link': LinkV2,
  'v2-link-off': LinkOff,
  'v2-open-new': OpenNew,
  'v2-plus-in-circle': PlusInCircleV2,
  'v2-letter': LetterV2,
  'v2-share': ShareV2,
  'v2-copy': CopyV2,
  'mail-sign-up': MailSignUp,
  'google-sign-in-redesigned': GoogleSignInRedesigned,
  'facebook-sign-in-redesigned': FacebookSignInRedesigned,
  suitcase: Suitcase,
  'google-sign-in-official': GoogleSignInOfficial,
  alert: Alert,
  meatballs: Meatballs,
  trophy: Trophy,
  'v2-trophy': TrophyWhite,
  'settings-v2': SettingsV2,
  'camera-v2': CameraV2,
  dollar: Dollar,
  art: ArtV2,
  brand: Brand,
  sales: Sales,
  'close-chat': CloseChat,
  'open-chat': OpenChat,
  'check-v2': CheckV2,
  'bin-v2': BinV2,
  'rtmp-icon': RtmpIcon,
  'sign-up-athlete-icon': SignUpAthleteIcon,
  'sign-up-fan-icon': SignUpFanIcon,
  'sign-up-content-creator-icon': SignUpContentCreatorIcon,
  'sign-up-organization-icon': SignUpOrganizationIcon,
  attach: Attach,
  'add-another': AddAnother,
  'icon-interview': IconInterview,
  pdf: Pdf,
  image: Image,
  document: Document,
  buyCart: BuyCart,
  tipHand: TipHand,
  settings: Settings,
};

export type IconNames = keyof typeof ICON_NAMES;

type IconProps = React.ComponentPropsWithoutRef<'svg'> & {
  name: IconNames;
  withoutFill?: boolean;
};

const Icon = ({ name, className, withoutFill, ...rest }: IconProps) => {
  const SvgTag = ICON_NAMES[name] as 'svg';

  return (
    <SvgTag
      className={cn(
        styles.root,
        {
          [styles.withoutFill]: withoutFill,
        },
        className
      )}
      data-icon-name={name}
      aria-hidden="true"
      role="presentation"
      focusable="false"
      {...rest}
    />
  );
};

export default Icon;
